import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"

import Section from "../components/section"
import TwmButton from "../components/twm-button"
import Part from "../components/part"

const OurServicesPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="Our Services" />
    <BackgroundImage
      fluid={props.data.ourServicesImage.childImageSharp.fluid}
      alt="business paperwork"
    >
      <Header headerText="Our Services"></Header>
    </BackgroundImage>
    <Section>
      <p>
        <strong>Wealth Planning</strong> - helping you achieve the things that
        are most important to you without jeopardising your financial security.
        We will help you establish your goals and plan how you can achieve these
        by striking the right balance between what you spend today and can save
        for tomorrow. A goal without a plan is just a wish.
      </p>

      <p>
        <strong>Safety Planning</strong> - making sure your loved ones,
        beneficiaries or your business are financially protected in the event of
        your death or serious illness.
      </p>

      <p>
        <strong>Tax Planning*</strong> - making use of allowances, exemptions and
        tax-planning opportunities to minimise your taxes.
      </p>

      <p>
        <strong>Investment Planning</strong> - ensuring we understand the risk
        you need to take, want to take and can tolerate to achieve your goals
        and ensure you have a portfolio that matches it. We will ensure
        appropriate asset allocation, safe custody and reliable administration,
        while minimising costs and taxes.
      </p>

      <p>
        <strong>
          The most common areas of advice that our clients require from us are
          as follows:
        </strong>
      </p>

      <ul>
        <li>
          <strong>Personal and corporate pension planning</strong>
        </li>
        <ul>
          <li>Reviewing existing pension holdings</li>
          <li>
            Making contributions into personal pensions from owner managed
            limited companies
          </li>
          <li>
            Establishing a retirement plan and putting a plan in place to
            achieve it
          </li>
        </ul>
        <li>
          <strong>Business Protection</strong>
        </li>
        <ul>
          <li>Shareholder protection</li>
          <li>Key man insurance</li>
          <li>Loan cover</li>
          <li>Relevant life plans</li>
          <li>Executive income protection</li>
        </ul>
        <li>
          <strong>Investment management</strong>
        </li>
        <ul>
          <li>
            Reviewing existing investment holdings such as ISA's, bonds, unit
            trusts
          </li>
          <li>
            Creating a strategy for tax efficient investing and withdrawals from
            portfolios
          </li>
        </ul>
        <li>
          <strong>Inheritance tax planning*</strong>
        </li>
        <ul>
          <li>
            Establishing and implementing a plan to minimise inheritance tax
            liability
          </li>
        </ul>
        <li>
          <strong>Trust advice</strong>
        </li>
        <ul>
          <li>Establishing and managing appropriate trusts</li>
        </ul>
      </ul>

      <p>*Tax and estate planning services are not regulated by the Financial Conduct Authority</p>

      <TwmButton toRef={props.pageContext.firm + "/contact-us"}>
        Contact Us
      </TwmButton>
    </Section>
  </Layout>
)

export default OurServicesPage

export const pageQuery = graphql`
  query {
    ourServicesImage: file(relativePath: { eq: "our-services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
